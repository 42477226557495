import React, { useState, useEffect, useContext } from "react";
import NavLinks from "../Navbar/NavLinks";
import { HashLink } from "react-router-hash-link";
import logo from "../../images/arcotrax-gradient.avif";
import {GlobalContext} from "../../context/GlobalContext";

const NavBar = () => {
  const [top, setTop] = useState(true);
  const [isOpen, setisOpen] = React.useState(false);
  const { state } = useContext(GlobalContext);

  function handleClick() {
    setisOpen(!isOpen);
  }

  const customScroll = (el) => {
    const yOffset = -70; // Adjust the offset value as needed
    const y = el.getBoundingClientRect().top + window.scrollY + yOffset;
    window.scrollTo({ top: y, behavior: 'smooth' });
  };

  useEffect(() => {
    const scrollHandler = () => {
      window.scrollY > 10 ? setTop(false) : setTop(true);
    };
    window.addEventListener("scroll", scrollHandler);
    return () => window.removeEventListener("scroll", scrollHandler);
  }, [top]);

  return (
    <nav
    style={{maxWidth: "100vw"}}
      className={`fixed top-0 w-full z-30 transition duration-300 ease-in-out mb-16 ${
        !top && "bg-neutral-900 shadow-lg"
      }`}
    >
      <div className="flex flex-row justify-between items-center py-2">
        <div className="flex flex-row justify-center md:px-12 md:mx-12 items-center text-center font-semibold">
          <HashLink smooth scroll={customScroll} to="/#hero">
            <img src={logo} alt="Logo" className="h-14" style={{filter: "drop-shadow(1px 1px 1px theme(colors.neutral.400))"}} />
          </HashLink><h2 className="text-2xl font-bold bg-clip-text text-transparent bg-gradient-to-b from-primary to-primary-custom" style={{filter: "drop-shadow(1px 1px 1px theme(colors.neutral.400))"}}>{state.displayName}</h2>
        </div>
        <div className="group flex flex-col items-center">
          <button
            className="p-2 rounded-lg lg:hidden text-neutral-400"
            onClick={handleClick}
          >
            <svg
              className="h-6 w-6 fill-current"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
            >
              {isOpen && (
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M18.278 16.864a1 1 0 0 1-1.414 1.414l-4.829-4.828-4.828 4.828a1 1 0 0 1-1.414-1.414l4.828-4.829-4.828-4.828a1 1 0 0 1 1.414-1.414l4.829 4.828 4.828-4.828a1 1 0 1 1 1.414 1.414l-4.828 4.829 4.828 4.828z"
                />
              )}
              {!isOpen && (
                <path
                  fillRule="evenodd"
                  d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z"
                />
              )}
            </svg>
          </button>
          <div className="hidden space-x-6 lg:inline-block p-5">
            <NavLinks scroll={customScroll} />
          </div>

          <div
            style={{maxWidth: "100vw"}}
            className={`fixed transition-transform duration-300 w-full ease-in-out transit flex justify-center left-0 h-auto rounded-md p-24 bg-neutral-900 lg:hidden shadow-xl top-14 ${
              isOpen ? "block" : "hidden"
            } ${top ? "bg-neutral-950" : "bg-neutral-900"}`}
          >
            <div className="flex flex-col space-y-6">
              <NavLinks scroll={customScroll} />
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default NavBar;
