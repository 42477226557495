import { useNavigate } from "react-router-dom";

const Button = ({ children, to, className, svgClass, onClick, id }) => {
  const navigate = useNavigate();
  const customOnClick = () => {
    if (to) {
      navigate(to);
    }
  };
  return (
    <div
      id={id}
      onClick={() => {
        if (onClick) {
          onClick();
        } else {
          customOnClick();
        }
      }}
      className={`group relative flex overflow-hidden bg-gradient-to-b from-primary to-primary-custom px-4 text-white text-lg rounded-2xl ml-2 w-full py-2 md:py-3 lg:py-3 ms:w-[150px] lg:w-[150px] cursor-pointer ${className}`}
    >
      <span className="relative z-10">{children}</span>
      <svg
        className={`w-4 h-4 group-hover:scale-[1] ml-1 mt-2 z-10 group-hover:translate-x-3 transition-all duration-700 ease-in-out ${svgClass}`}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill="currentColor"
      >
        <path
          fillRule="evenodd"
          d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
          clipRule="evenodd"
        ></path>
      </svg>
      <div className="absolute inset-0 bg-gradient-to-b from-primary-custom to-primary-custom opacity-0 group-hover:opacity-100 transition-opacity duration-700 ease-in-out"></div>
    </div>
  );
};

export default Button;
