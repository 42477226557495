import React, { useContext } from "react";
// import {Link} from 'react-router-dom';
import { HashLink } from "react-router-hash-link";
import { GlobalContext } from "../context/GlobalContext";

const Footer = () => {
  const { state } = useContext(GlobalContext);
  return (
    <>
      <footer>
        <div className="footer max-w-full mx-auto px-4 sm:px-6 bg-neutral-950 border-t border-b py-30">
          {/* Top area: Blocks */}
          <div className="grid sm:grid-cols-12 gap-5 py-8 md:py-12 border-t border-neutral-950 lg:ml-11">
            {/* 1st block */}
            <div className="col-span-12 lg:col-span-4">
              <div className="box-border border-b-4 border-neutral-950 p-8 bg-neutral-800 text-gray-600 text-center rounded-lg xl:w-80 mx-auto">
                <h3 className="font-bold text-4xl bg-clip-text text-transparent bg-gradient-to-b from-primary to-primary-custom mb-4">
                  {state.displayName}
                </h3>
                <div className="text-md font-medium text-neutral-300">
                  <h5>South Africa</h5>
                </div>
              </div>
            </div>

            {/* 2nd block */}
            <div className="col-span-6 md:col-span-6 lg:col-span-1 ml-7 mx-auto">
              <h6 className="bg-clip-text text-transparent bg-gradient-to-b from-primary to-primary-custom text-xl font-bold mb-4">
                LINKS
              </h6>
              <ul className="text-md">
                <li className="mb-2">
                  <HashLink
                    to="/#hero"
                    className="text-neutral-300 hover:text-primary hover:tracking-wider transition-all duration-250 ease-in-out"
                  >
                    Home
                  </HashLink>
                </li>
                <li className="mb-2">
                  <HashLink
                    to="/#about"
                    className="text-neutral-300 hover:text-primary hover:tracking-wider transition-all duration-250 ease-in-out"
                  >
                    About
                  </HashLink>
                </li>
                <li className="mb-2">
                  <HashLink
                    to="/#services"
                    className="text-neutral-300 hover:text-primary hover:tracking-wider transition-all duration-250 ease-in-out"
                  >
                    Services
                  </HashLink>
                </li>
                <li className="mb-2">
                  <HashLink
                    to="/#clients"
                    className="text-neutral-300 hover:text-primary hover:tracking-wider transition-all duration-250 ease-in-out"
                  >
                    Our Clients
                  </HashLink>
                </li>
                <li className="mb-2">
                  <HashLink
                    to="/contact"
                    className="text-neutral-300 hover:text-primary hover:tracking-wider transition-all duration-250 ease-in-out"
                  >
                    Contact
                  </HashLink>
                </li>
              </ul>
            </div>

            {/* 3rd block */}
            <div className="col-span-6 md:col-span-6 lg:col-span-4 mx-auto w-60">
              <h6 className="bg-clip-text text-transparent bg-gradient-to-b from-primary to-primary-custom text-xl font-bold mb-4">
                OUR SERVICES
              </h6>
              <ul className="text-md">
                <li className="mb-2">
                  <HashLink
                    to="/#services"
                    className="text-neutral-300 hover:text-primary hover:tracking-wider transition-all duration-250 ease-in-out"
                  >
                    Web Development
                  </HashLink>
                </li>
                <li className="mb-2">
                  <HashLink
                    to="/#services"
                    className="text-neutral-300 hover:text-primary hover:tracking-wider transition-all duration-250 ease-in-out"
                  >
                    Mobile App Development
                  </HashLink>
                </li>
              </ul>
            </div>

            {/* 4th block */}
            <div className="col-span-12 text-center mx-auto lg:col-span-3 font-bold uppercase">
              <div className="text-xl mb-6 bg-clip-text text-transparent bg-gradient-to-b from-primary to-primary-custom">
                Social Media Links.
              </div>

              <div className="text-md font-medium mb-6 text-neutral-300">
                Follow us on social media.
              </div>
              <div className="mx-auto text-center mt-2">
                <ul className="flex justify-center mb-4 md:mb-0">
                  {/* <li>
                                        <Link to="#" className="flex justify-center items-center text-neutral-400 hover:text-gray-500 bg-white hover:bg-white-100 rounded-full shadow transition-all duration-150 ease-in-out" aria-label="Twitter">
                                        <svg className="w-8 h-8 fill-current" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M24 11.5c-.6.3-1.2.4-1.9.5.7-.4 1.2-1 1.4-1.8-.6.4-1.3.6-2.1.8-.6-.6-1.5-1-2.4-1-1.7 0-3.2 1.5-3.2 3.3 0 .3 0 .5.1.7-2.7-.1-5.2-1.4-6.8-3.4-.3.5-.4 1-.4 1.7 0 1.1.6 2.1 1.5 2.7-.5 0-1-.2-1.5-.4 0 1.6 1.1 2.9 2.6 3.2-.3.1-.6.1-.9.1-.2 0-.4 0-.6-.1.4 1.3 1.6 2.3 3.1 2.3-1.1.9-2.5 1.4-4.1 1.4H8c1.5.9 3.2 1.5 5 1.5 6 0 9.3-5 9.3-9.3v-.4c.7-.5 1.3-1.1 1.7-1.8z" />
                                        </svg>
                                        </Link>
                                    </li> */}
                  {/* <li className="ml-4">
                                        <Link to="#" className="flex justify-center items-center text-neutral-400 hover:text-gray-500 bg-white hover:bg-white-100 rounded-full shadow transition-all duration-150 ease-in-out" aria-label="Facebook">
                                        <svg className="w-8 h-8 fill-current" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M14.023 24L14 17h-3v-3h3v-2c0-2.7 1.672-4 4.08-4 1.153 0 2.144.086 2.433.124v2.821h-1.67c-1.31 0-1.563.623-1.563 1.536V14H21l-1 3h-2.72v7h-3.257z" />
                                        </svg>
                                        </Link>
                                    </li> */}
                  <li className="ml-4">
                    <a
                      href="https://www.linkedin.com/company/bridgeway-labs"
                      target="_blank"
                      rel="noreferrer"
                      className="rounded-full flex justify-center bg-white h-8 text-blue-900  w-8  mx-1 text-center pt-1"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        className="fill-current font-black hover:animate-pulse"
                      >
                        <circle cx="4.983" cy="5.009" r="2.188"></circle>
                        <path d="M9.237 8.855v12.139h3.769v-6.003c0-1.584.298-3.118 2.262-3.118 1.937 0 1.961 1.811 1.961 3.218v5.904H21v-6.657c0-3.27-.704-5.783-4.526-5.783-1.835 0-3.065 1.007-3.568 1.96h-.051v-1.66H9.237zm-6.142 0H6.87v12.139H3.095z"></path>
                      </svg>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="flex flex-wrap items-center md:justify-between justify-center mx-auto px-4">
            <div className="w-full md:w-4/12 px-4 mx-auto text-center py-2">
              <div className="text-sm text-neutral-300 font-semibold py-1">
                Copyright &copy; {new Date().getFullYear()}
                {"  "}
                <HashLink
                  to="#"
                  className="bg-clip-text text-transparent bg-gradient-to-b from-primary to-primary-custom"
                >
                  {state.displayName}
                </HashLink>
                . All rights reserved.
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};
export default Footer;
