import React, { useState, useEffect, useRef, useContext } from "react";
import NavBar from "../components/Navbar/NavBar";
import Footer from "../components/Footer";
import { useDocTitle } from "../components/CustomHook";
import Notiflix from "notiflix";
import emailjs from "@emailjs/browser";
import Button from "../components/Button";
import { GlobalContext } from "../context/GlobalContext";

const ContactForm = () => {
  const { state } = useContext(GlobalContext);

  useDocTitle(`${state.displayName} - Send us a message`);

  const [inputFields, setInputFields] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    message: "",
  });
  const [errors, setErrors] = useState({});

  const validateValues = (inputValues) => {
    let errors = {};
    if (inputValues.firstName.length < 1) {
      errors.firstName = "First Name can't be blank";
    }
    if (inputValues.lastName.length < 1) {
      errors.lastName = "Last Name can't be blank";
    }
    if (inputValues.email.length < 1) {
      errors.email = "Email can't be blank";
    }
    if (inputValues.phone.length < 1) {
      errors.phone = "Phone can't be blank";
    }
    if (inputValues.message.length < 1) {
      errors.message = "Message can't be blank";
    }
    return errors;
  };

  const handleChange = (e) => {
    setInputFields({ ...inputFields, [e.target.name]: e.target.value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setErrors(validateValues(inputFields));
    if (Object.keys(validateValues(inputFields)).length === 0) sendEmail();
  };

  const form = useRef();

  const handleButtonState = (submitting) => {
    const div = document.getElementById("submitBtn");
    div.style.pointerEvents = submitting ? "none" : "auto";
    div.style.cursor = submitting ? "not-allowed" : "pointer";

    const span = div.querySelector("span");
    span.textContent = submitting ? "Loading..." : "send message";
  };

  const sendEmail = () => {
    handleButtonState(true);

    emailjs
      .sendForm(
        process.env.REACT_APP_EMAILJS_SERVICE_ID,
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
        form.current,
        process.env.REACT_APP_EMAILJS_PUBLIC_KEY_ID
      )
      .then(
        () => {
          Notiflix.Report.success("Success", "Message sent", "Okay");
          handleButtonState(false);
        },
        () => {
          Notiflix.Report.failure(
            "An error occurred",
            "There was an issue sending your message",
            "Okay"
          );
          handleButtonState(false);
        }
      );
  };

  const finishSubmit = () => {
    setInputFields({
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      message: "",
    });
  };
  useEffect(() => {
    if (Object.keys(errors).length === 0) {
      finishSubmit();
    }
  }, [errors]);

  return (
    <>
      <div>
        <NavBar />
      </div>
      <div
        id="contact"
        className="flex justify-center items-center mt-8 w-full bg-neutral-950 py-12 lg:py-24 "
      >
        <div
          className="container mx-auto my-8 px-4 lg:px-20"
          data-aos="zoom-in"
        >
          <form ref={form} onSubmit={handleSubmit}>
            <div className="w-full bg-neutral-800 p-8 my-4 md:px-12 lg:w-9/12 lg:pl-20 lg:pr-40 mr-auto rounded-2xl shadow-2xl">
              <div className="flex">
                <h1 className="font-bold text-center lg:text-left bg-clip-text text-transparent bg-gradient-to-b from-primary to-primary-custom uppercase text-4xl">
                  Send us a message
                </h1>
              </div>
              <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                <div>
                  <input
                    name="firstName"
                    className="w-full bg-neutral-300 text-neutral-950 mt-2 p-3 rounded-lg focus:outline-none"
                    type="text"
                    placeholder="First Name*"
                    value={inputFields.firstName}
                    onChange={handleChange}
                  />
                  {errors.firstName ? (
                    <p className="text-red-500 text-sm">{errors.firstName}</p>
                  ) : null}
                </div>

                <div>
                  <input
                    name="lastName"
                    className="w-full bg-neutral-300 text-neutral-950 mt-2 p-3 rounded-lg focus:outline-none"
                    type="text"
                    placeholder="Last Name*"
                    value={inputFields.lastName}
                    onChange={handleChange}
                  />
                  {errors.lastName ? (
                    <p className="text-red-500 text-sm">{errors.lastName}</p>
                  ) : null}
                </div>

                <div>
                  <input
                    name="email"
                    className="w-full bg-neutral-300 text-neutral-950 mt-2 p-3 rounded-lg focus:outline-none"
                    type="text"
                    placeholder="Email*"
                    value={inputFields.email}
                    onChange={handleChange}
                  />
                  {errors.email ? (
                    <p className="text-red-500 text-sm">{errors.email}</p>
                  ) : null}
                </div>

                <div>
                  <input
                    name="phone"
                    className="w-full bg-neutral-300 text-neutral-950 mt-2 p-3 rounded-lg focus:outline-none"
                    type="number"
                    placeholder="Phone*"
                    value={inputFields.phone}
                    onChange={handleChange}
                  />
                  {errors.phone ? (
                    <p className="text-red-500 text-sm">{errors.phone}</p>
                  ) : null}
                </div>
              </div>
              <div className="my-4">
                <textarea
                  name="message"
                  placeholder="Message*"
                  className="w-full h-32 bg-neutral-300 text-neutral-950 mt-2 p-3 rounded-lg focus:outline-none"
                  value={inputFields.message}
                  onChange={handleChange}
                ></textarea>
                {errors.message ? (
                  <p className="text-red-500 text-sm">{errors.message}</p>
                ) : null}
              </div>
              <div className="my-2 w-1/2 lg:w-2/4">
                {/* <button
                  type="submit"
                  id="submitBtn"
                  className="uppercase text-sm font-bold tracking-wide bg-neutral-900 hover:bg-primary text-neutral-300 p-3 rounded-lg w-full 
                                    focus:outline-none focus:shadow-outline"
                >
                  Send Message
                </button> */}
                <Button
                  id="submitBtn"
                  onClick={() =>
                    form.current.dispatchEvent(
                      new Event("submit", { cancelable: true, bubbles: true })
                    )
                  }
                  svgClass="hidden"
                  className="w-full md:w-full lg:w-full ml-0 rounded-lg justify-center uppercase text-sm font-bold tracking-wide"
                >
                  Send Message
                </Button>
              </div>
            </div>
          </form>
          <div className="w-full  lg:-mt-96 lg:w-2/6 px-8 py-6 ml-auto bg-gradient-to-l from-primary to-primary-custom rounded-2xl">
            <div className="flex flex-col text-white">
              <div className="flex my-4 w-2/3 lg:w-3/4">
                <div className="flex flex-col">
                  <i className="fas fa-map-marker-alt pt-2 pr-2" />
                </div>
                <div className="flex flex-col">
                  <h2 className="text-2xl">Office Location</h2>
                  <p className="">South Africa</p>
                </div>
              </div>

              <div className="flex my-4 w-2/3 lg:w-1/2">
                <div className="flex flex-col">
                  <i className="fas fa-phone-alt pt-2 pr-2" />
                </div>

                <div className="flex flex-col">
                  <h2 className="text-2xl">Send an E-mail</h2>
                  <p className="">
                    {state.displayName === "Arcotrax"
                      ? "hello@arcotrax.tech"
                      : "hello@bridgewaylab.com"}
                  </p>
                </div>
              </div>

              <div className="flex my-4 w-2/3 lg:w-1/2">
                <div className="flex flex-col">
                  <i className="fas fa-phone-alt pt-2 pr-2" />
                </div>

                {/* <div className="flex flex-col">
                  <h2 className="text-2xl">Give us a call</h2>
                  <p className="">+27 65 073 6997</p>
                </div> */}
              </div>

              <div className="flex my-4 w-2/3 lg:w-1/2">
                <a
                  href="https://www.linkedin.com/company/bridgeway-labs"
                  target="_blank"
                  rel="noreferrer"
                  className="rounded-full flex justify-center bg-white h-8 text-blue-900  w-8  mx-1 text-center pt-1"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    className="fill-current font-black hover:animate-pulse"
                  >
                    <circle cx="4.983" cy="5.009" r="2.188"></circle>
                    <path d="M9.237 8.855v12.139h3.769v-6.003c0-1.584.298-3.118 2.262-3.118 1.937 0 1.961 1.811 1.961 3.218v5.904H21v-6.657c0-3.27-.704-5.783-4.526-5.783-1.835 0-3.065 1.007-3.568 1.96h-.051v-1.66H9.237zm-6.142 0H6.87v12.139H3.095z"></path>
                  </svg>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ContactForm;
