import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "./index.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
// All pages
import Home from "./pages/Home";
// import Contact from './pages/Contact';

import { useDocTitle } from "./components/CustomHook";
import ScrollToTop from "./components/ScrollToTop";
import ContactForm from "./pages/ContactForm";
import WebDev from "./components/WebDev";
import { GlobalProvider } from "./context/GlobalContext";
import DynamicMeta from "./components/DynamicMeta";

function App() {
  useEffect(() => {
    const aos_init = () => {
      AOS.init({
        once: true,
        duration: 1000,
        easing: "ease-out-cubic",
      });
    };

    // window.addEventListener("load", () => {
    aos_init();
    // });
  }, []);

  useDocTitle(
    `${
      window.location.hostname.includes("bridgewaylab")
        ? "Bridgeway Lab"
        : "Arcotrax"
    } - Your Contact for Excellent Software Applications, Services, and Solutions.`
  );

  return (
    <>
      <GlobalProvider>
        <Router>
          <ScrollToTop>
            <DynamicMeta />
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/contact" element={<ContactForm />} />
              <Route path="/web-dev" element={<WebDev />} />
            </Routes>
          </ScrollToTop>
        </Router>
      </GlobalProvider>
    </>
  );
}

export default App;
