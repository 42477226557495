import React from "react";
import Button from "./Button";
const Cta = () => {
  return (
    <div className="w-full flex items-center justify-center text-white cta">
      <div className="mx-8 w-full h-96 text-center lg:text-left py-16 px-12 flex lg:justify-between items-center">
        <div className="w-full flex flex-col lg:flex-row lg:justify-around">
          <div className="mb-4">
            <p className="text-2xl md:text-4xl font-bold mb-4 text-neutral-300">
              Are you ready to scale your business?
            </p>
            <p className="text-lg md:text-2xl text-neutral-300">
              Get in touch and let us build something amazing{" "}
              <span className="font-black">together!</span>
            </p>
          </div>

          <div className="w-full lg:w-72 pt-6 lg:mx-12">
            <Button
              className="w-[205px] md:w-[205px] lg:w-[205px] py-3 rounded-lg border from-transparent to-transparent"
              to="/contact"
            >
              Send us a message
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cta;
