import heroImg from "../images/consultation-copy.svg";

const WebDev = () => {
  return (
    <div className="flex w-full bg-[#ACACAB] h-screen">
      <img
        alt="card img"
        className="rounded-t float-right duration-1000 w-1/4 ml-80"
        src={heroImg}
      />
    </div>
  );
};

export default WebDev;
