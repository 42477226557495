import React from "react";
import firebase from "../images/techstack/transparent/firebase-small.png";
import googlecloud from "../images/techstack/transparent/Google-Cloud@3x.png";
import htmlcssjs from "../images/techstack/transparent/CSS-HTML-JS@3x.png";
import mongo from "../images/techstack/transparent/Mongo-DB@3x.png";
import node from "../images/techstack/transparent/Node-JS@3x.png";
import postgres from "../images/techstack/transparent/PostgreSQL@3x.png";
import rails from "../images/techstack/transparent/Rails@3x.png";
import react from "../images/techstack/transparent/React@3x.png";
import ruby from "../images/techstack/transparent/Ruby@3x.png";
import typescript from "../images/techstack/transparent/typescript.png";

const clientImage = {
  // height: "22rem",
  // width: "auto",
  // mixBlendMode: "colorBurn",
};

const Stack = () => {
  return (
    <div id="stack" className="mt-8 bg-neutral-900">
      <section data-aos="fade-up">
        <div className="my-4 py-4">
          <h2 className="my-2 text-center text-3xl bg-clip-text text-transparent bg-gradient-to-b from-primary to-primary-custom uppercase font-bold">
            Tools & Technologies
          </h2>
          <div className="flex justify-center">
            <div className="w-24 h-1 bg-gradient-to-b from-primary to-primary-custom"></div>
          </div>
        </div>

        <div className="pb-12 px-8">
          <div className="my-14 flex bg-gradient-to-b from-primary to-primary-custom w-full border-2 inset-0 border-primary rounded-3xl">
            <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-5 lg:grid-cols-8 xl:grid-cols-10 w-full auto-rows-auto py-6 pl-9">
              <div className="relative inline-block w-[120px] h-[120px]">
                <div className="rounded-full object-cover w-[120px] h-[120px] relative z-10 bg-gray-300"></div>
                <img
                  src={htmlcssjs}
                  alt="Your round"
                  className="mx-auto mb-6 h-[90px] absolute z-20 ml-[15px] mt-[-105px]"
                />
                <div className="absolute inset-0 rounded-full border-2 border-primary z-20"></div>
              </div>
              <div className="relative inline-block w-[120px] h-[120px]">
                <div className="rounded-full object-cover w-[120px] h-[120px] relative z-10 bg-gray-300"></div>
                <img
                  src={react}
                  alt="Your round"
                  className="mx-auto mb-6 h-[90px] absolute z-20 ml-[15px] mt-[-105px]"
                />
                <div className="absolute inset-0 rounded-full border-2 border-primary z-20"></div>
              </div>
              <div className="relative inline-block w-[120px] h-[120px]">
                <div className="rounded-full object-cover w-[120px] h-[120px] relative z-10 bg-gray-300"></div>
                <img
                  src={node}
                  alt="Your round"
                  className="mx-auto mb-6 h-[90px] absolute z-20 ml-[15px] mt-[-105px]"
                />
                <div className="absolute inset-0 rounded-full border-2 border-primary z-20"></div>
              </div>
              <div className="relative inline-block w-[120px] h-[120px]">
                <div className="rounded-full object-cover w-[120px] h-[120px] relative z-10 bg-gray-300"></div>
                <img
                  src={typescript}
                  alt="Your round"
                  className="mx-auto mb-6 h-[55px] absolute z-20 ml-[32px] mt-[-86px]"
                />
                <div className="absolute inset-0 rounded-full border-2 border-primary z-20"></div>
              </div>
              <div className="relative inline-block w-[120px] h-[120px]">
                <div className="rounded-full object-cover w-[120px] h-[120px] relative z-10 bg-gray-300"></div>
                <img
                  src={ruby}
                  alt="Your round"
                  className="mx-auto mb-6 h-[90px] absolute z-20 ml-[15px] mt-[-105px]"
                />
                <div className="absolute inset-0 rounded-full border-2 border-primary z-20"></div>
              </div>
              <div className="relative inline-block w-[120px] h-[120px]">
                <div className="rounded-full object-cover w-[120px] h-[120px] relative z-10 bg-gray-300"></div>
                <img
                  src={rails}
                  alt="Your round"
                  className="mx-auto mb-6 h-[90px] absolute z-20 ml-[15px] mt-[-105px]"
                />
                <div className="absolute inset-0 rounded-full border-2 border-primary z-20"></div>
              </div>
              <div className="relative inline-block w-[120px] h-[120px]">
                <div className="rounded-full object-cover w-[120px] h-[120px] relative z-10 bg-gray-300"></div>
                <img
                  src={googlecloud}
                  alt="Your round"
                  className="mx-auto mb-6 h-[90px] absolute z-20 ml-[15px] mt-[-105px]"
                />
                <div className="absolute inset-0 rounded-full border-2 border-primary z-20"></div>
              </div>
              <div className="relative inline-block w-[120px] h-[120px]">
                <div className="rounded-full object-cover w-[120px] h-[120px] relative z-10 bg-gray-300"></div>
                <img
                  src={firebase}
                  alt="Your round"
                  className="mx-auto mb-6 h-[65px] absolute z-20 ml-[12px] mt-[-92px]"
                />
                <div className="absolute inset-0 rounded-full border-2 border-primary z-20"></div>
              </div>
              <div className="relative inline-block w-[120px] h-[120px]">
                <div className="rounded-full object-cover w-[120px] h-[120px] relative z-10 bg-gray-300"></div>
                <img
                  src={postgres}
                  alt="Your round"
                  className="mx-auto mb-6 h-[90px] absolute z-20 ml-[15px] mt-[-105px]"
                />
                <div className="absolute inset-0 rounded-full border-2 border-primary z-20"></div>
              </div>
              <div className="relative inline-block w-[120px] h-[120px]">
                <div className="rounded-full object-cover w-[120px] h-[120px] relative z-10 bg-gray-300"></div>
                <img
                  src={mongo}
                  alt="Your round"
                  className="mx-auto mb-6 h-[90px] absolute z-20 ml-[15px] mt-[-105px]"
                />
                <div className="absolute inset-0 rounded-full border-2 border-primary z-20"></div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Stack;
