import { useEffect } from "react";

const DynamicMeta = () => {
  useEffect(() => {
    const hostname = window.location.hostname.includes("bridgewaylab")
      ? "bridgewaylab.com"
      : "arcotrax.wep.app";

    // Update link href
    const canonicalLink = document.querySelector('link[rel="canonical"]');
    if (canonicalLink) {
      canonicalLink.href = `https://${hostname}/`;
    }

    // Update meta tags
    const ogSiteNameMeta = document.querySelector(
      'meta[property="og:site_name"]'
    );
    if (ogSiteNameMeta) {
      ogSiteNameMeta.content = hostname;
    }

    const ogTitleMeta = document.querySelector('meta[property="og:title"]');
    if (ogTitleMeta) {
      ogTitleMeta.content = hostname;
    }

    const ogDescriptionMeta = document.querySelector(
      'meta[property="og:description"]'
    );
    if (ogDescriptionMeta) {
      ogDescriptionMeta.content = `Welcome to ${hostname} - Your Path to Digital Excellence. We provide services, solutions, and software applications. Contact us for more information.`;
    }
  }, []);

  return null;
};

export default DynamicMeta;
