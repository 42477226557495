import React, { useEffect } from "react";
import { HashLink } from "react-router-hash-link";
import { useLocation } from "react-router-dom";
const NavLinks = ({ scroll }) => {
  const location = useLocation();

  const handleClick = (event) => {
    const buttons = document.querySelectorAll(".link-list");
    buttons.forEach((button) => {
      button.classList.remove("bg-clip-text");
      button.classList.remove("text-transparent");
      button.classList.remove("bg-gradient-to-b");
      button.classList.remove("from-primary");
      button.classList.remove("to-primary-custom");
      if (!button.classList.contains("text-neutral-300")) {
        button.classList.add("text-neutral-300");
      }
    });

    if (event.target.classList.contains("text-neutral-300")) {
      event.target.classList.remove("text-neutral-300");
    }
    event.target.classList.add("bg-clip-text");
    event.target.classList.add("text-transparent");
    event.target.classList.add("bg-gradient-to-b");
    event.target.classList.add("from-primary");
    event.target.classList.add("to-primary-custom");
    //bg-clip-text text-transparent bg-gradient-to-b from-primary to-primary-custom
  };

  useEffect(() => {
    const buttons = document.querySelectorAll(".link-list");
    buttons.forEach((button) => {
      if (location.pathname === "/" && location.hash === "") {
        if (button.getAttribute("href") === "/#hero") {
          button.classList.add("bg-clip-text");
          button.classList.add("text-transparent");
          button.classList.add("bg-gradient-to-b");
          button.classList.add("from-primary");
          button.classList.add("to-primary-custom");
          button.classList.remove("text-neutral-300");
        }
      } else if (button.getAttribute("href").includes(location.hash)) {
        button.classList.add("bg-clip-text");
        button.classList.add("text-transparent");
        button.classList.add("bg-gradient-to-b");
        button.classList.add("from-primary");
        button.classList.add("to-primary-custom");
        button.classList.remove("text-neutral-300");
      }
    });
  }, [location]);

  return (
    <>
      <HashLink
        className="px-4 link-list font-extrabold text-neutral-300 hover:text-primary-custom focus:text-primary"
        smooth
        to="/#hero"
        onClick={handleClick}
        scroll={scroll}
      >
        Home
      </HashLink>
      <HashLink
        className="px-4 link-list font-extrabold text-neutral-300 hover:text-primary-custom focus:text-primary"
        smooth
        to="/#about"
        onClick={handleClick}
        scroll={scroll}
        id="about-link"
      >
        About
      </HashLink>
      <HashLink
        className="px-4 link-list font-extrabold text-neutral-300 hover:text-primary-custom focus:text-primary"
        smooth
        to="/#services"
        onClick={handleClick}
        scroll={scroll}
      >
        Services
      </HashLink>
      <HashLink
        className="px-4 link-list font-extrabold text-neutral-300 hover:text-primary-custom focus:text-primary"
        smooth
        to="/#clients"
        onClick={handleClick}
        scroll={scroll}
      >
        Our Clients
      </HashLink>
      <HashLink
        className="px-4 link-list font-extrabold text-neutral-300 hover:text-primary-custom focus:text-primary"
        smooth
        to="/#stack"
        onClick={handleClick}
        scroll={scroll}
      >
        Our Tools
      </HashLink>
      <HashLink
        className="px-4 link-list font-extrabold text-neutral-300 hover:text-primary-custom focus:text-primary"
        to="/contact#contact"
        onClick={handleClick}
        scroll={scroll}
      >
        Contact Us
      </HashLink>
    </>
  );
};

export default NavLinks;
