import React, { createContext, useState } from "react";

export const GlobalContext = createContext();

export const GlobalProvider = ({ children }) => {
  const [state, setState] = useState({
    displayName: window.location.hostname.includes("bridgewaylab")
      ? "Bridgeway Lab"
      : "Arcotrax",
  });

  const setDisplayName = (name) => {
    setState((prevState) => ({ ...prevState, name }));
  };

  return (
    <GlobalContext.Provider value={{ state, setDisplayName }}>
      {children}
    </GlobalContext.Provider>
  );
};
