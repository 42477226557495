import React from "react";
import img from "../images/Web-developer.svg";
import Button from "./Button";

const Intro = () => {
  return (
    <>
      <div className="m-auto max-w-6xl p-2 md:p-12 h-5/6" id="about">
        <h2 className="my-2 text-center text-3xl text-primary uppercase font-bold bg-clip-text text-transparent bg-gradient-to-b from-primary to-primary-custom">
          About Us
        </h2>
        <div className="flex justify-center">
          <div className="w-24 h-1 bg-gradient-to-b from-primary to-primary-custom"></div>
        </div>
        <div
          className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left"
          data-aos="fade-up"
        >
          <div className="lg:w-1/2 flex flex-col lg:mx-4 justify-center">
            <img alt="card img" className="rounded-t float-right" src={img} />
          </div>
          <div
            className="flex-col my-4 text-center lg:text-left lg:my-0 lg:justify-end w-full lg:w-1/2 px-8"
            data-aos="zoom-in"
            data-aos-delay="500"
          >
            <h3 className="text-3xl text-primary font-bold">
              We specialize in crafting bespoke web solutions tailored to your
              unique needs.
            </h3>
            <div>
              <p className="my-3 text-xl text-neutral-400 font-semibold">
                Our seasoned team of software engineers thrives on challenges,
                transforming ideas into seamless, user-centric applications.
              </p>
            </div>

            <div>
              <p className="my-3 text-xl text-neutral-400 font-semibold">
                Bridging innovation with technology, we pave the way for your
                success. From concept to deployment, we're your trusted partners
                in the ever-evolving world of digital solutions.
              </p>
            </div>
            <Button to="/contact">Contact us</Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Intro;
