import React from "react";
import fulcrum from "../images/clients/logo-pin-1.avif";
import dupreez from "../images/clients/dupreezcrest-edit-transformed-smaller.avif";
import threedom from "../images/clients/threedom-logo-text.avif";
import enlee from "../images/clients/enlee.avif";

const clientImage = {
  height: "30rem",
  width: "auto",
  mixBlendMode: "colorBurn",
};

const Clients = () => {
  return (
    <div id="clients" className="mt-8">
      <section data-aos="fade-up">
        <div className="mt-4 py-4">
          <h2 className="my-2 text-center text-3xl bg-clip-text text-transparent bg-gradient-to-b from-primary to-primary-custom uppercase font-bold">
            Some of Our Clients
          </h2>
          <div className="flex justify-center">
            <div className="w-24 h-1 bg-gradient-to-b from-primary to-primary-custom"></div>
          </div>
        </div>

        <div className="">
          <div className="grid xs:grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
            <div
              style={clientImage}
              className="overflow-hidden flex flex-col justify-center items-center transition-all ease-in-out opacity-100"
              data-aos="zoom-in"
              data-aos-delay="600"
            >
              <img src={enlee} alt="client" className="h-2/4 border" />
              <p className="font-bold text-center bg-clip-text text-transparent bg-gradient-to-b from-primary to-primary-custom">
                Enlee Stevedoring
              </p>
              <p className="w-1/2 text-center text-neutral-300">
                A bespoke project management platform where they can track
                labour spent on vessels and related statistics.
              </p>
            </div>
            <div
              style={clientImage}
              className="overflow-hidden flex flex-col justify-center items-center transition-all ease-in-out opacity-100"
              data-aos="zoom-in"
              data-aos-delay="600"
            >
              <img
                src={fulcrum}
                alt="client"
                className="h-2/4 filter drop-shadow-custom"
              />
              <p className="font-bold text-center bg-clip-text text-transparent bg-gradient-to-b from-primary to-primary-custom">
                Fulcrum Analytics (Pty) Ltd
              </p>
              <p className="w-1/2 text-center text-neutral-300">
                In collaboration with Fulcrum Analytic's Tech team we built the
                I-Spy intelligence monitoring platform for capturing and viewing
                area intelligence reports.
              </p>
            </div>
            <div
              style={clientImage}
              className="overflow-hidden flex flex-col justify-center items-center transition-all ease-in-out opacity-100"
              data-aos="zoom-in"
              data-aos-delay="600"
            >
              <img
                src={threedom}
                alt="client"
                className="h-2/4 filter drop-shadow-custom"
              />
              <p className="font-bold text-center bg-clip-text text-transparent bg-gradient-to-b from-primary to-primary-custom">
                Threedom Consulting & Sales
              </p>
              <p className="w-1/2 text-center text-neutral-300">
                For Threedom Consulting & Sales we created a business website
                and bespoke eCommerce platform.
              </p>
            </div>
            <div
              style={clientImage}
              className="overflow-hidden flex flex-col justify-center items-center transition-all ease-in-out opacity-100"
              data-aos="zoom-in"
              data-aos-delay="600"
            >
              <img
                src={dupreez}
                alt="client"
                className="h-2/4 filter drop-shadow-custom"
              />
              <p className="text-center font-semibold bg-clip-text text-transparent bg-gradient-to-b from-primary to-primary-custom">
                The History of the Du Preez Family in South Africa
              </p>
              <p className="w-1/2 text-center text-neutral-300">
                For the History of the Du Preez Family in South Africa we
                created a bespoke genealogy website.
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Clients;
